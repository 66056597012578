import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';

const MercadoPago = () => {
  const [cuit, setCuit] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [retryCount, setRetryCount] = useState(0);
  // const navigate = useNavigate();

  useEffect(() => {
    const loadCuitFromSessionStorage = async () => {
      const storedCuit = sessionStorage.getItem('cuit');
      if (storedCuit) {
        setCuit(storedCuit);
        setErrorMessage(null); // Limpiamos el mensaje de error si se encuentra el CUIT
        setLoading(false);
      } else {
        const token = sessionStorage.getItem('token');
        if (token) {
          try {
            const response = await fetch('https://tippitqr.com/userdb/token', {
              method: 'GET',
              headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
            });
            if (response.ok) {
              const data = await response.json();
              if (data.cuit) {
                setCuit(data.cuit);
                sessionStorage.setItem('cuit', data.cuit); // Guardamos el CUIT en el sessionStorage
                sessionStorage.setItem('vinculado_mp', data.vinculado_mp);
                setErrorMessage(null);
                setLoading(false);
                return;
              }
            } else {
              throw new Error('Error al obtener el CUIT del usuario');
            }
          } catch (error) {
            console.error('Error al obtener el CUIT del usuario:', error);
            setErrorMessage('No se pudo obtener el CUIT del usuario. Por favor, inténtelo de nuevo en unos minutos.');
          }
        }

        if (retryCount < 3) {
          setRetryCount(retryCount + 1);
          setErrorMessage('No se encontró el CUIT. Intentando nuevamente en unos segundos...');
          setTimeout(loadCuitFromSessionStorage, 2000); // Reintentamos después de 2 segundos
        } else {
          setLoading(false);
          setErrorMessage('Por favor, aguarde un instante...');
        }
      }
    };

    loadCuitFromSessionStorage();
  }, [retryCount]);

  const handleConnectClick = async () => {
    if (!cuit) {
      return;
    }

    try {
      const response = await fetch(`https://tippitqr.com/mp/link_mp_auth?cuit=${cuit}`);

      if (!response.ok) {
        throw new Error('No se pudo obtener el enlace de conexión de Mercado Pago');
      }

      const data = await response.json();
      console.log('Se recuperó exitosamente el enlace de conexión de Mercado Pago:', data);
      setErrorMessage("");
      window.location.href = data.link;

    } catch (error) {
      console.error('Error al obtener el enlace de conexión de Mercado Pago:', error);
      setErrorMessage('No se pudo conectar con Mercado Pago. Por favor, inténtelo de nuevo en unos minutos.');
    }
  };

  return (
    <div>
      {loading ? (
        <p>Cargando...</p>
      ) : (
        <>
          {errorMessage && <p className="error pt-4 text-sm">{errorMessage}</p>}
          {!errorMessage && (
            <>
              <button className="transition:all 
                        duration-300 
                        ease-in-out 
                        w-full 
                        px-2 
                        py-4 
                        text-white 
                        font-semibold 
                        bg-[#FF5EA3] 
                        rounded-lg 
                        hover:bg-[#FF7DF2] 
                        hover:scale-105 
                        mt-5" onClick={handleConnectClick}>Conectar</button>
              <p className="mt-2 text-sm mb-4">Tranqui, son dos minutos.</p>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default MercadoPago;
